<template>
  <v-dialog
    :value="showAccount811AccessDialog"
    max-width="600px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card style="background-color: #f1f2f1">
      <v-toolbar dark color="primary" ref="toolbar">
        <v-toolbar-title>Account Access</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('account-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        :style="{
          'background-color': '#f1f2f1',
          height: $vuetify.breakpoint.xsOnly ? `${contentHeight}px` : '65vh',
          'overflow-y': 'auto',
          'max-height': $vuetify.breakpoint.xsOnly ? undefined : '60vh',
        }"
      >
        <div class="py-5">
          <b>The user has access to the following accounts:</b>
        </div>

        <v-data-table
          id="user811AccountAccess"
          item-key="locate_request_provider_account_id"
          :headers="headers"
          hide-default-footer
          disable-pagination
          fixed-header
          :items="locateRequestProviderAccounts"
          show-select
          v-model="selectedlocateRequestProviderAccounts"
        >
        </v-data-table>
      </v-card-text>

      <v-card-actions class="d-flex justify-end py-5 px-7" ref="cardAction">
        <v-btn
          id="saveUser811AccountAccess"
          color="primary"
          @click="saveAccount811Access"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import contentHeightMixin from "@/mixins/contentHeightMixin";
import axios from "axios";

const APIURL = process.env.VUE_APP_API_URL;

const headers = [
  {
    text: "811 Code",
    align: "start",
    sortable: false,
    value: "label",
  },
];

export default {
  name: "Account811AccessDialog",
  mixins: [contentHeightMixin],
  props: {
    showAccount811AccessDialog: Boolean,
    locateRequestProviderAccounts: Array,
    locateRequestProviderAccountRestrictions: Array,
    selectedUser: Object,
  },
  data() {
    return {
      headers,
      selectedlocateRequestProviderAccounts: [],
    };
  },
  methods: {
    async saveAccount811Access() {
      await axios.delete(
        `${APIURL}/locate_request_provider_account_restrictions/${this.selectedUser.user_id}`
      );
      if (this.selectedlocateRequestProviderAccounts.length > 0) {
        const selectedlocateRequestProviderAccountIds =
          this.selectedlocateRequestProviderAccounts.map(
            (s) => s.locate_request_provider_account_id
          );
        await axios.post(
          `${APIURL}/locate_request_provider_account_restrictions/${this.selectedUser.user_id}`,
          {
            locate_request_provider_account_ids:
              this.locateRequestProviderAccounts
                .filter((a) => {
                  return !selectedlocateRequestProviderAccountIds.includes(
                    a.locate_request_provider_account_id
                  );
                })
                .map((s) => s.locate_request_provider_account_id),
          }
        );
      }
      this.$emit("accounts-saved");
    },
    getSavedRestrictions() {
      const locateRequestProviderAccountRestrictionIds =
        this.locateRequestProviderAccountRestrictions.map(
          (l) => l.locate_request_provider_account_id
        );
      this.selectedlocateRequestProviderAccounts =
        this.locateRequestProviderAccounts.filter((l) => {
          return !locateRequestProviderAccountRestrictionIds.includes(
            l.locate_request_provider_account_id
          );
        });
    },
  },
  beforeMount() {
    this.getSavedRestrictions();
  },
};
</script>
