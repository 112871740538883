var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-3" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-center justify-space-between mb-1",
          staticStyle: { width: "750px", "max-width": "750px" },
        },
        [
          _c(
            "div",
            { staticClass: "caption" },
            [
              _c("v-icon", { attrs: { size: "16px" } }, [
                _vm._v(" " + _vm._s(_vm.mdiPuzzle) + " "),
              ]),
              _vm._v(" Integrations "),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass: "px-0 mx-0",
                  attrs: {
                    text: "",
                    color: "primary",
                    id: "addIntegrationButton",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showAddIntegrationDialog = true
                    },
                  },
                },
                [
                  _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                    _vm._v(" " + _vm._s(_vm.mdiPlus) + " "),
                  ]),
                  _vm._v(" Integration "),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-card",
        { attrs: { width: "750px", "max-width": "750px" } },
        [
          _c(
            "v-card-text",
            { staticClass: "ma-0 pa-0" },
            [
              _vm.cityworksIntegrationInfo.api_url
                ? _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-center justify-space-between px-5 py-3 cursor-pointer",
                      on: {
                        click: function ($event) {
                          _vm.showCityworksIntegrationDialog = true
                        },
                      },
                    },
                    [
                      _c(
                        "section",
                        { staticClass: "flex-grow-1 font-weight-medium" },
                        [_vm._v("Cityworks")]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "v-btn",
                            { attrs: { icon: "" } },
                            [
                              _c("v-icon", [
                                _vm._v(" " + _vm._s(_vm.mdiMenuRight) + " "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              [
                _vm.ARC_GIS_CONNECTION_TYPES.AGOL,
                _vm.ARC_GIS_CONNECTION_TYPES.PORTAL,
              ].includes(_vm.auth.organization.gis_connection_type)
                ? _c("v-divider")
                : _vm._e(),
              _vm.cityworksIntegrationInfo.api_url ||
              _vm.hasValidCityworksToken ||
              [
                _vm.ARC_GIS_CONNECTION_TYPES.AGOL,
                _vm.ARC_GIS_CONNECTION_TYPES.PORTAL,
              ].includes(_vm.auth.organization.gis_connection_type)
                ? _c("div", [
                    [
                      _vm.ARC_GIS_CONNECTION_TYPES.AGOL,
                      _vm.ARC_GIS_CONNECTION_TYPES.PORTAL,
                    ].includes(_vm.auth.organization.gis_connection_type)
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-center justify-space-between px-5 py-3",
                          },
                          [
                            _c(
                              "section",
                              { staticClass: "flex-grow-1 font-weight-medium" },
                              [
                                _vm._v(
                                  " Connected to " +
                                    _vm._s(
                                      _vm.getArcGisConnectionType(
                                        _vm.auth.organization
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: { "offset-y": "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        dark: "",
                                                        width: "30px",
                                                        "min-width": "30px",
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.mdiDotsVertical
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      860736107
                                    ),
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      { staticClass: "py-0" },
                                      [
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass: "py-1",
                                            on: {
                                              click:
                                                _vm.disconnectArcGisConnection,
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-action-text",
                                              {
                                                staticClass:
                                                  "d-flex gap align-center",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: { color: "red" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.mdiPuzzleRemove
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "overflowText",
                                                    staticStyle: {
                                                      color: "red",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Remove Integration "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _c("div", { staticClass: "px-5 py-3 text-center" }, [
                    _vm._v(" No Integrations Have Been Added "),
                  ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showAddIntegrationDialog
        ? _c("AddIntegrationDialog", {
            attrs: {
              showAddIntegrationDialog: _vm.showAddIntegrationDialog,
              userGroupIntegrationSettings: _vm.userGroupIntegrationSettings,
            },
            on: {
              "add-integration-dialog-close": function ($event) {
                _vm.showAddIntegrationDialog = false
              },
              "connection-success": function ($event) {
                _vm.showAddIntegrationDialog = false
                _vm.updateConnectionData()
              },
              "cityworks-settings-saved": function ($event) {
                _vm.showAddIntegrationDialog = false
                _vm.updateConnectionData()
              },
            },
          })
        : _vm._e(),
      _vm.showCityworksIntegrationDialog
        ? _c("CityworksIntegrationDialog", {
            attrs: {
              showCityworksIntegrationDialog:
                _vm.showCityworksIntegrationDialog,
            },
            on: {
              "cityworks-integration-dialog-close": function ($event) {
                _vm.showCityworksIntegrationDialog = false
              },
              "cityworks-settings-saved": function ($event) {
                return _vm.$router.go(0)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }