<template>
  <div class="pt-3">
    <p class="caption">Save 811 Tickets to Cityworks Service Request</p>

    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" class="py-0 my-0">
            <p class="caption py-0 my-0">Authentication</p>
            <div v-if="hasValidCityworksToken" class="d-flex align-center gap">
              <div>Signed In</div>
              <v-btn text color="primary" @click="signOutOfCityworks">
                Sign Out
              </v-btn>
            </div>
            <div v-else class="d-flex align-center gap">
              <div>Not Signed In</div>
              <v-btn
                text
                color="primary"
                @click="showCityworksSignInDialog = true"
              >
                Sign In
              </v-btn>

              <CityworksSignInDialog
                v-if="showCityworksSignInDialog"
                :cityworksIntegrationInfo="cityworksIntegrationInfo"
                :showCityworksSignInDialog="showCityworksSignInDialog"
                @cityworks-sign-in-dialog-close="
                  showCityworksSignInDialog = false
                "
                @cityworks-sign-in-dialog-succeed="
                  showCityworksSignInDialog = false;
                  updateCityworksToken();
                "
              />
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" class="py-0 my-0">
            <validation-provider
              v-slot="{ errors, valid }"
              name="Service Request Domain"
              v-if="hasValidCityworksToken"
            >
              <v-select
                label="Service Request Domain"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="requestDomain"
                :items="serviceRequestDomainChoices"
                item-text="label"
                item-value="value"
                v-model="cityworksIntegrationSettings.service_request_domain_id"
                @change="
                  cityworksIntegrationSettings.service_request_template_id =
                    undefined;
                  getCityworksRequestTemplates();
                  $emit(
                    'cityworks-integration-settings-changed',
                    cityworksIntegrationSettings
                  );
                "
              >
              </v-select>
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Service Request Template"
              v-if="hasValidCityworksToken"
            >
              <v-select
                label="Service Request Template"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="requestTemplate"
                :items="serviceRequestTemplateChoices"
                item-text="label"
                item-value="value"
                v-model="
                  cityworksIntegrationSettings.service_request_template_id
                "
                @change="
                  $emit(
                    'cityworks-integration-settings-changed',
                    cityworksIntegrationSettings
                  )
                "
              >
              </v-select>
            </validation-provider>
          </v-col>

          <v-col cols="12" sm="6" class="py-0 my-0">
            <v-switch
              v-if="hasValidCityworksToken"
              label="Enable Integration"
              v-model="cityworksInfo.cityworks_save_locate_request"
              @change="
                $emit('cityworks-user-group-info-changed', cityworksInfo)
              "
              hide-details
            >
            </v-switch>

            <v-switch
              v-if="hasValidCityworksToken"
              label="Attach PDF"
              v-model="cityworksIntegrationSettings.attach_pdf"
              @change="
                $emit(
                  'cityworks-integration-settings-changed',
                  cityworksIntegrationSettings
                )
              "
              hide-details
            ></v-switch>

            <v-switch
              v-if="hasValidCityworksToken"
              label="Attach Photos"
              v-model="cityworksIntegrationSettings.attach_photos"
              @change="
                $emit(
                  'cityworks-integration-settings-changed',
                  cityworksIntegrationSettings
                )
              "
              hide-details
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import CityworksSignInDialog from "@/components/organization/integrations/shared/cityworks-integration-dialog/save-811-tickets-to-cityworks/CityworksSignInDialog";
import authObjectMixin from "@/mixins/authObjectMixin";
import { mdiInformation } from "@mdi/js";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "Save811TicketsToCityworks",
  components: {
    CityworksSignInDialog,
  },
  computed: {
    serviceRequestDomainChoices() {
      return this.domains.map((d) => {
        const { DomainId, Description } = d;
        return {
          value: DomainId,
          label: Description,
        };
      });
    },
    serviceRequestTemplateChoices() {
      return this.requestTemplates.map((r) => {
        const { ProblemSid, ProblemCode } = r;
        return {
          value: ProblemSid,
          label: ProblemCode,
        };
      });
    },
  },
  data() {
    return {
      auth: {},
      hasValidCityworksToken: false,
      showCityworksSignInDialog: false,
      cityworksIntegrationSettings: {
        service_request_domain_id: undefined,
        service_request_domain_desc: "",
        service_request_template_id: undefined,
        service_request_template_desc: "",
        attach_pdf: undefined,
        attach_photos: undefined,
      },
      cityworksInfo: {
        api_url: "",
        cityworks_save_locate_request: false,
      },
      domains: [],
      requestTemplates: [],
      mdiInformation,
    };
  },
  props: {
    cityworksIntegrationInfo: Object,
  },
  watch: {
    cityworksIntegrationInfo: {
      deep: true,
      handler(val) {
        this.cityworksInfo = { ...val };
      },
    },
  },
  async beforeMount() {
    this.auth = JSON.parse(localStorage.getItem("auth"));
    this.cityworksInfo = { ...this.cityworksIntegrationInfo };
    await this.validateCityworksToken();
    await this.getCityworksLocateRequestSettings();
    await this.getCityworksDomains();
    await this.getCityworksRequestTemplates();
  },
  mixins: [authObjectMixin],
  methods: {
    async validateCityworksToken() {
      try {
        const {
          data: {
            results: { service_request_token: serviceRequestToken },
          },
        } = await axios.get(`${APIURL}/cityworks/locate_request_integration`);
        this.hasValidCityworksToken = Boolean(serviceRequestToken);
      } catch (error) {
        this.hasValidCityworksToken = false;
      }
    },
    async signOutOfCityworks() {
      const { user_id: userId } = this.auth;
      await axios.post(
        `${APIURL}/cityworks/service_request_sign_out`,
        undefined,
        {
          params: {
            user_id: userId,
          },
        }
      );
      await this.updateCityworksToken();
    },
    async updateCityworksToken() {
      await this.updateAuthObject();
      await this.validateCityworksToken();
      await this.getCityworksDomains();
      await this.getCityworksRequestTemplates();
    },
    async getCityworksDomains() {
      if (!this.hasValidCityworksToken) {
        return;
      }
      const {
        data: { results },
      } = await axios.get(`${APIURL}/cityworks/domains`, {
        params: {
          use_811_service_request_token: true,
        },
      });
      this.domains = results;
    },
    async getCityworksRequestTemplates() {
      if (
        !this.cityworksIntegrationSettings.service_request_domain_id ||
        !this.hasValidCityworksToken
      ) {
        return;
      }
      try {
        const {
          data: { results },
        } = await axios.get(`${APIURL}/cityworks/problems`, {
          params: {
            domain_id:
              this.cityworksIntegrationSettings.service_request_domain_id,
            use_811_service_request_token: true,
          },
        });
        this.requestTemplates = results;
      } catch (error) {
        this.requestTemplates = [];
      }
    },
    async getCityworksLocateRequestSettings() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/cityworks/locate_request_integration`);
      this.cityworksIntegrationSettings = results;
      const {
        service_request_domain_id: serviceReqDomainId,
        service_request_domain_desc: serviceReqDomainDesc,
        service_request_template_id: serviceReqTemplateId,
        service_request_template_desc: serviceReqTemplateDesc,
        attach_pdf: attachPdf,
        attach_photos: attachPhotos,
      } = this.cityworksIntegrationSettings;
      const settings = {
        request_domain_id: serviceReqDomainId,
        service_request_domain_desc: serviceReqDomainDesc,
        service_request_template_id: serviceReqTemplateId,
        service_request_template_desc: serviceReqTemplateDesc,
        attach_pdf: attachPdf,
        attach_photos: attachPhotos,
      };
      await this.getCityworksRequestTemplates();
      this.$emit("cityworks-integration-settings-changed", settings);
    },
  },
};
</script>

<style scoped>
.gap {
  gap: 5px;
}
</style>
