var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showAddIntegrationDialog,
        "max-width": "400px",
        persistent: "",
      },
    },
    [
      _c(
        "v-card",
        { staticStyle: { "background-color": "#f1f2f1" } },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", color: "primary" },
            },
            [
              _c("v-toolbar-title", [_vm._v("Select an Integration")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("add-integration-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "d-flex justify-center gap pa-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    dark: "",
                    id: "arcGISIntegrationButton",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showGisConnectionDialog = true
                    },
                  },
                },
                [_vm._v(" GIS Connection ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", dark: "" },
                  on: {
                    click: function ($event) {
                      _vm.showCityworksIntegrationDialog = true
                    },
                  },
                },
                [_vm._v(" Cityworks ")]
              ),
              _vm.showCityworksIntegrationDialog
                ? _c("CityworksIntegrationDialog", {
                    attrs: {
                      showCityworksIntegrationDialog:
                        _vm.showCityworksIntegrationDialog,
                    },
                    on: {
                      "cityworks-integration-dialog-close": function ($event) {
                        _vm.showCityworksIntegrationDialog = false
                      },
                      "cityworks-settings-saved": function ($event) {
                        _vm.showCityworksIntegrationDialog = false
                        _vm.$emit("cityworks-settings-saved")
                      },
                    },
                  })
                : _vm._e(),
              _vm.showGisConnectionDialog
                ? _c("GisConnectionDialog", {
                    attrs: {
                      userGroupIntegrationSettings:
                        _vm.userGroupIntegrationSettings,
                      showGisConnectionDialog: _vm.showGisConnectionDialog,
                    },
                    on: {
                      "gis-connection-dialog-close": function ($event) {
                        _vm.showGisConnectionDialog = false
                      },
                      "connection-success": function ($event) {
                        _vm.showGisConnectionDialog = false
                        _vm.$emit("connection-success")
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }