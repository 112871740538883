var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-3" },
    [
      _c("p", { staticClass: "caption" }, [
        _vm._v("Save 811 Tickets to Cityworks Service Request"),
      ]),
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0 my-0",
                      attrs: { cols: "12", sm: "6" },
                    },
                    [
                      _c("p", { staticClass: "caption py-0 my-0" }, [
                        _vm._v("Authentication"),
                      ]),
                      _vm.hasValidCityworksToken
                        ? _c(
                            "div",
                            { staticClass: "d-flex align-center gap" },
                            [
                              _c("div", [_vm._v("Signed In")]),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: { click: _vm.signOutOfCityworks },
                                },
                                [_vm._v(" Sign Out ")]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "d-flex align-center gap" },
                            [
                              _c("div", [_vm._v("Not Signed In")]),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showCityworksSignInDialog = true
                                    },
                                  },
                                },
                                [_vm._v(" Sign In ")]
                              ),
                              _vm.showCityworksSignInDialog
                                ? _c("CityworksSignInDialog", {
                                    attrs: {
                                      cityworksIntegrationInfo:
                                        _vm.cityworksIntegrationInfo,
                                      showCityworksSignInDialog:
                                        _vm.showCityworksSignInDialog,
                                    },
                                    on: {
                                      "cityworks-sign-in-dialog-close":
                                        function ($event) {
                                          _vm.showCityworksSignInDialog = false
                                        },
                                      "cityworks-sign-in-dialog-succeed":
                                        function ($event) {
                                          _vm.showCityworksSignInDialog = false
                                          _vm.updateCityworksToken()
                                        },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0 my-0",
                      attrs: { cols: "12", sm: "6" },
                    },
                    [
                      _vm.hasValidCityworksToken
                        ? _c("validation-provider", {
                            attrs: { name: "Service Request Domain" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors, valid }) {
                                    return [
                                      _c("v-select", {
                                        attrs: {
                                          label: "Service Request Domain",
                                          "hide-details": "auto",
                                          "error-messages": errors,
                                          success: valid,
                                          color: "primary",
                                          name: "requestDomain",
                                          items:
                                            _vm.serviceRequestDomainChoices,
                                          "item-text": "label",
                                          "item-value": "value",
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.cityworksIntegrationSettings.service_request_template_id =
                                              undefined
                                            _vm.getCityworksRequestTemplates()
                                            _vm.$emit(
                                              "cityworks-integration-settings-changed",
                                              _vm.cityworksIntegrationSettings
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.cityworksIntegrationSettings
                                              .service_request_domain_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.cityworksIntegrationSettings,
                                              "service_request_domain_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "cityworksIntegrationSettings.service_request_domain_id",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4254379879
                            ),
                          })
                        : _vm._e(),
                      _vm.hasValidCityworksToken
                        ? _c("validation-provider", {
                            attrs: { name: "Service Request Template" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors, valid }) {
                                    return [
                                      _c("v-select", {
                                        attrs: {
                                          label: "Service Request Template",
                                          "hide-details": "auto",
                                          "error-messages": errors,
                                          success: valid,
                                          color: "primary",
                                          name: "requestTemplate",
                                          items:
                                            _vm.serviceRequestTemplateChoices,
                                          "item-text": "label",
                                          "item-value": "value",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$emit(
                                              "cityworks-integration-settings-changed",
                                              _vm.cityworksIntegrationSettings
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.cityworksIntegrationSettings
                                              .service_request_template_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.cityworksIntegrationSettings,
                                              "service_request_template_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                cityworksIntegrationSettings.service_request_template_id\n              ",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2519687525
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0 my-0",
                      attrs: { cols: "12", sm: "6" },
                    },
                    [
                      _vm.hasValidCityworksToken
                        ? _c("v-switch", {
                            attrs: {
                              label: "Enable Integration",
                              "hide-details": "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$emit(
                                  "cityworks-user-group-info-changed",
                                  _vm.cityworksInfo
                                )
                              },
                            },
                            model: {
                              value:
                                _vm.cityworksInfo.cityworks_save_locate_request,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.cityworksInfo,
                                  "cityworks_save_locate_request",
                                  $$v
                                )
                              },
                              expression:
                                "cityworksInfo.cityworks_save_locate_request",
                            },
                          })
                        : _vm._e(),
                      _vm.hasValidCityworksToken
                        ? _c("v-switch", {
                            attrs: { label: "Attach PDF", "hide-details": "" },
                            on: {
                              change: function ($event) {
                                return _vm.$emit(
                                  "cityworks-integration-settings-changed",
                                  _vm.cityworksIntegrationSettings
                                )
                              },
                            },
                            model: {
                              value:
                                _vm.cityworksIntegrationSettings.attach_pdf,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.cityworksIntegrationSettings,
                                  "attach_pdf",
                                  $$v
                                )
                              },
                              expression:
                                "cityworksIntegrationSettings.attach_pdf",
                            },
                          })
                        : _vm._e(),
                      _vm.hasValidCityworksToken
                        ? _c("v-switch", {
                            attrs: {
                              label: "Attach Photos",
                              "hide-details": "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$emit(
                                  "cityworks-integration-settings-changed",
                                  _vm.cityworksIntegrationSettings
                                )
                              },
                            },
                            model: {
                              value:
                                _vm.cityworksIntegrationSettings.attach_photos,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.cityworksIntegrationSettings,
                                  "attach_photos",
                                  $$v
                                )
                              },
                              expression:
                                "cityworksIntegrationSettings.attach_photos",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }