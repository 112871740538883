<template>
  <div class="pt-3">
    <p class="caption">API URL</p>

    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="py-0 my-0">
            <validation-provider
              v-slot="{ errors, valid }"
              name="API URL"
              :rules="{
                regex:
                  /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
              }"
            >
              <v-text-field
                v-model="cityworksInfo.api_url"
                hide-details="auto"
                name="apiUrl"
                background-color="white"
                label="API URL"
                :error-messages="errors"
                :success="valid"
                hint="Example: https://myCity.com/cityworks/Services/"
                persistent-hint
                @input="
                  $emit('cityworks-user-group-info-changed', cityworksInfo)
                "
              >
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ApiUrl",
  data() {
    return {
      cityworksInfo: {
        api_url: "",
      },
    };
  },
  props: {
    cityworksIntegrationInfo: Object,
  },
  beforeMount() {
    this.cityworksInfo = { ...this.cityworksIntegrationInfo };
  },
  watch: {
    cityworksIntegrationInfo: {
      deep: true,
      handler(val) {
        this.cityworksInfo = { ...val };
      },
    },
  },
};
</script>
