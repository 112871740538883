var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-3" },
    [
      _c("div", { staticClass: "d-flex justify-space-between align-end" }, [
        _c("div", { staticClass: "caption mb-2" }, [_vm._v("Information")]),
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("v-switch", {
              staticClass: "mr-2",
              attrs: { label: "Admin" },
              on: {
                change: function ($event) {
                  return _vm.$emit("input", _vm.user)
                },
              },
              model: {
                value: _vm.user.isAdmin,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "isAdmin", $$v)
                },
                expression: "user.isAdmin",
              },
            }),
            _c("v-switch", {
              staticClass: "ml-2",
              attrs: { label: "Active", id: "activeInactiveUserToggle" },
              on: {
                change: function ($event) {
                  return _vm.$emit("input", _vm.user)
                },
              },
              model: {
                value: _vm.user.isActive,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "isActive", $$v)
                },
                expression: "user.isActive",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "v-card",
        { attrs: { id: "userInformation" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("validation-provider", {
                        attrs: { name: "First Name", rules: "required" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "First Name",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "value",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$emit("input", _vm.user)
                                    },
                                  },
                                  model: {
                                    value: _vm.user.firstName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.user, "firstName", $$v)
                                    },
                                    expression: "user.firstName",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("validation-provider", {
                        attrs: { name: "Last Name", rules: "required" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Last Name",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "value",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$emit("input", _vm.user)
                                    },
                                  },
                                  model: {
                                    value: _vm.user.lastName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.user, "lastName", $$v)
                                    },
                                    expression: "user.lastName",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("validation-provider", {
                        attrs: { name: "Username", rules: "required" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Username",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "value",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$emit("input", _vm.user)
                                    },
                                  },
                                  model: {
                                    value: _vm.user.username,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.user, "username", $$v)
                                    },
                                    expression: "user.username",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm.connectedToEsri
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("validation-provider", {
                            attrs: { name: "ArcGIS Username" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors, valid }) {
                                    return [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "ArcGIS Username",
                                          "hide-details": "auto",
                                          "error-messages": errors,
                                          success: valid,
                                          color: "primary",
                                          name: "value",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.$emit("input", _vm.user)
                                          },
                                        },
                                        model: {
                                          value: _vm.user.esriUsername,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user,
                                              "esriUsername",
                                              $$v
                                            )
                                          },
                                          expression: "user.esriUsername",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1853097221
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("validation-provider", {
                        attrs: {
                          name: "Email",
                          rules: { required: true, email: true },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Email",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "value",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$emit("input", _vm.user)
                                    },
                                  },
                                  model: {
                                    value: _vm.user.email,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.user, "email", $$v)
                                    },
                                    expression: "user.email",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("validation-provider", {
                        attrs: {
                          name: "Cell Phone",
                          rules: {
                            regex:
                              /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-text-field", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: "##########",
                                      expression: "'##########'",
                                    },
                                  ],
                                  attrs: {
                                    label: "Cell Phone",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "value",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$emit("input", _vm.user)
                                    },
                                  },
                                  model: {
                                    value: _vm.user.cellPhone,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.user, "cellPhone", $$v)
                                    },
                                    expression: "user.cellPhone",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "validation-provider",
                        [
                          _c("v-select", {
                            attrs: {
                              "hide-details": "auto",
                              name: "timeZone",
                              "background-color": "white",
                              items: _vm.TIME_ZONE_CHOICES,
                              "item-text": "label",
                              "item-value": "value",
                              label: "Time Zone",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("input", _vm.user)
                              },
                            },
                            model: {
                              value: _vm.user.timeZone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.user,
                                  "timeZone",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "user.timeZone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "validation-provider",
                        [
                          _c("v-select", {
                            attrs: {
                              "hide-details": "auto",
                              name: "mapIdSelected",
                              "background-color": "white",
                              items: _vm.mapChoices,
                              "item-text": "label",
                              "item-value": "value",
                              label: "Map to Load After Signin",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("input", _vm.user)
                              },
                            },
                            model: {
                              value: _vm.user.mapIdSelected,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.user,
                                  "mapIdSelected",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "user.mapIdSelected",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }