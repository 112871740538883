<template>
  <v-dialog :value="showAddIntegrationDialog" max-width="400px" persistent>
    <v-card style="background-color: #f1f2f1">
      <v-toolbar dark color="primary" class="elevation-0">
        <v-toolbar-title>Select an Integration</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('add-integration-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="d-flex justify-center gap pa-5">
        <v-btn
          color="primary"
          dark
          @click="showGisConnectionDialog = true"
          id="arcGISIntegrationButton"
        >
          GIS Connection
        </v-btn>
        <v-btn
          color="primary"
          dark
          @click="showCityworksIntegrationDialog = true"
        >
          Cityworks
        </v-btn>

        <CityworksIntegrationDialog
          v-if="showCityworksIntegrationDialog"
          :showCityworksIntegrationDialog="showCityworksIntegrationDialog"
          @cityworks-integration-dialog-close="
            showCityworksIntegrationDialog = false
          "
          @cityworks-settings-saved="
            showCityworksIntegrationDialog = false;
            $emit('cityworks-settings-saved');
          "
        />

        <GisConnectionDialog
          v-if="showGisConnectionDialog"
          :userGroupIntegrationSettings="userGroupIntegrationSettings"
          :showGisConnectionDialog="showGisConnectionDialog"
          @gis-connection-dialog-close="showGisConnectionDialog = false"
          @connection-success="
            showGisConnectionDialog = false;
            $emit('connection-success');
          "
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CityworksIntegrationDialog from "@/components/organization/integrations/shared/CityworksIntegrationDialog";
import GisConnectionDialog from "@/components/organization/integrations/shared/GisConnectionDialog";

export default {
  name: "AddIntegrationDialog",
  components: {
    CityworksIntegrationDialog,
    GisConnectionDialog,
  },
  props: {
    showAddIntegrationDialog: Boolean,
    userGroupIntegrationSettings: Object,
  },
  data() {
    return {
      showCityworksIntegrationDialog: false,
      showGisConnectionDialog: false,
    };
  },
};
</script>

<style scoped>
.gap {
  gap: 10px;
}
</style>
