<template>
  <div class="pt-3">
    <div class="d-flex justify-space-between align-end">
      <div class="caption mb-2">Information</div>
      <div class="d-flex">
        <v-switch
          v-model="user.isAdmin"
          label="Admin"
          @change="$emit('input', user)"
          class="mr-2"
        >
        </v-switch>
        <v-switch
          v-model="user.isActive"
          label="Active"
          @change="$emit('input', user)"
          id="activeInactiveUserToggle"
          class="ml-2"
        ></v-switch>
      </div>
    </div>

    <v-card id="userInformation">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <validation-provider
              v-slot="{ errors, valid }"
              name="First Name"
              rules="required"
            >
              <v-text-field
                label="First Name"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="value"
                v-model="user.firstName"
                @input="$emit('input', user)"
              >
              </v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="6">
            <validation-provider
              v-slot="{ errors, valid }"
              name="Last Name"
              rules="required"
            >
              <v-text-field
                label="Last Name"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="value"
                v-model="user.lastName"
                @input="$emit('input', user)"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <validation-provider
              v-slot="{ errors, valid }"
              name="Username"
              rules="required"
            >
              <v-text-field
                label="Username"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="value"
                v-model="user.username"
                @input="$emit('input', user)"
              >
              </v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="6" v-if="connectedToEsri">
            <validation-provider
              v-slot="{ errors, valid }"
              name="ArcGIS Username"
            >
              <v-text-field
                label="ArcGIS Username"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="value"
                v-model="user.esriUsername"
                @input="$emit('input', user)"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <validation-provider
              v-slot="{ errors, valid }"
              name="Email"
              :rules="{ required: true, email: true }"
            >
              <v-text-field
                label="Email"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="value"
                v-model="user.email"
                @input="$emit('input', user)"
              >
              </v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="6">
            <validation-provider
              v-slot="{ errors, valid }"
              name="Cell Phone"
              :rules="{
                regex:
                  /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
              }"
            >
              <v-text-field
                label="Cell Phone"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="value"
                v-model="user.cellPhone"
                v-mask="'##########'"
                @input="$emit('input', user)"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <validation-provider>
              <v-select
                v-model.trim="user.timeZone"
                hide-details="auto"
                name="timeZone"
                background-color="white"
                :items="TIME_ZONE_CHOICES"
                item-text="label"
                item-value="value"
                label="Time Zone"
                @change="$emit('input', user)"
              >
              </v-select>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="6">
            <validation-provider>
              <v-select
                v-model.trim="user.mapIdSelected"
                hide-details="auto"
                name="mapIdSelected"
                background-color="white"
                :items="mapChoices"
                item-text="label"
                item-value="value"
                label="Map to Load After Signin"
                @change="$emit('input', user)"
              >
              </v-select>
            </validation-provider>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
const APIURL = process.env.VUE_APP_API_URL;
const TIME_ZONE_CHOICES = [
  { value: "US/Pacific", label: "US/Pacific" },
  { value: "US/Mountain", label: "US/Mountain" },
  { value: "US/Central", label: "US/Central" },
  { value: "US/Eastern", label: "US/Eastern" },
];

export default {
  name: "InformationForm",
  props: {
    selectedUser: Object,
    userGroup: Object,
  },
  computed: {
    connectedToEsri() {
      const { gis_connection_type: gisConnectionType } = this.userGroup;
      return gisConnectionType === "agol" || gisConnectionType === "portal";
    },
    mapChoices() {
      return this.maps.map((m) => {
        const { map_id: mapId, name } = m;
        return {
          value: mapId,
          label: name,
        };
      });
    },
  },
  async beforeMount() {
    const {
      username,
      f_name: firstName,
      l_name: lastName,
      email,
      is_gis_admin: isAdmin,
      is_power_user: isFieldReporter,
      locate_requests_user: isLocator,
      time_zone: timeZone,
      last_map_opened: mapIdSelected,
      sms_notification: cellPhone,
      esri_username: esriUsername,
      is_active: isActive,
    } = this.selectedUser;
    this.user = {
      ...this.user,
      username,
      firstName,
      lastName,
      email,
      isAdmin,
      isFieldReporter,
      isLocator,
      timeZone,
      mapIdSelected,
      cellPhone,
      esriUsername,
      isActive,
    };
    await this.getAllMaps();
  },
  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        username: "",
        esriUsername: "",
        email: "",
        cellPhone: "",
        timeZone: "",
        isAdmin: false,
        isLocator: false,
        isFieldReporter: false,
        mapIdSelected: undefined,
        isActive: true,
      },
      TIME_ZONE_CHOICES,
      maps: [],
      cityworksIntegrationInfo: {},
      hasValidCityworksToken: false,
    };
  },
  methods: {
    async getLocateRequestMaps() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/maps/withlocateservice`);
      this.maps = results;
    },
    async getAllMaps() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/maps`);
      this.maps = results;
    },
  },
};
</script>
