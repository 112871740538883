<template>
  <v-list-item id="userAccessPermissions" class="px-0">
    <v-list-item-content class="pb-0">
      <section class="d-flex align-center justify-space-between">
        <div>811 Codes</div>

        <div class="d-flex gap">
          <v-chip
            :color="
              selected811AccountRestrictionChoice ===
              ACCOUNT_811_RESTRICTION_CHOICES.ALL
                ? 'primary'
                : undefined
            "
            @click="saveAccount811Access(ACCOUNT_811_RESTRICTION_CHOICES.ALL)"
          >
            All
          </v-chip>
          <v-chip
            :color="
              selected811AccountRestrictionChoice ===
              ACCOUNT_811_RESTRICTION_CHOICES.SELECTED
                ? 'primary'
                : undefined
            "
            @click="showAccount811AccessDialog = true"
          >
            Selected
          </v-chip>
          <v-chip
            :color="
              selected811AccountRestrictionChoice ===
              ACCOUNT_811_RESTRICTION_CHOICES.NONE
                ? 'primary'
                : undefined
            "
            @click="saveAccount811Access(ACCOUNT_811_RESTRICTION_CHOICES.NONE)"
          >
            None
          </v-chip>
        </div>
      </section>

      <Account811AccessDialog
        v-if="showAccount811AccessDialog"
        :showAccount811AccessDialog="showAccount811AccessDialog"
        :locateRequestProviderAccounts="locateRequestProviderAccounts"
        :locateRequestProviderAccountRestrictions="
          locateRequestProviderAccountRestrictions
        "
        :selectedUser="selectedUser"
        @account-dialog-close="showAccount811AccessDialog = false"
        @accounts-saved="onAccountsSaved"
      />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import contentHeightMixin from "@/mixins/contentHeightMixin";
import Account811AccessDialog from "@/components/organization/users/edit-user-dialog/account-811-form/Account811AccessDialog";
import axios from "axios";

const APIURL = process.env.VUE_APP_API_URL;
const ACCOUNT_811_RESTRICTION_CHOICES = {
  ALL: "all",
  SELECTED: "selected",
  NONE: "none",
};

export default {
  name: "Account811Form",
  props: {
    selectedUser: Object,
  },
  mixins: [contentHeightMixin],
  components: {
    Account811AccessDialog,
  },
  data() {
    return {
      showAccount811AccessDialog: false,
      locateRequestProviderAccounts: [],
      locateRequestProviderAccountRestrictions: [],
      ACCOUNT_811_RESTRICTION_CHOICES,
    };
  },
  computed: {
    selected811AccountRestrictionChoice() {
      const locateRequestProviderAccountRestrictionIds =
        this.locateRequestProviderAccountRestrictions.map(
          (l) => l.locate_request_provider_account_id
        );
      const allRestricted =
        locateRequestProviderAccountRestrictionIds.length > 0 &&
        this.locateRequestProviderAccounts.every((l) => {
          return locateRequestProviderAccountRestrictionIds.includes(
            l.locate_request_provider_account_id
          );
        });

      const someRestricted = this.locateRequestProviderAccounts.some((l) => {
        return locateRequestProviderAccountRestrictionIds.includes(
          l.locate_request_provider_account_id
        );
      });

      if (allRestricted) {
        return ACCOUNT_811_RESTRICTION_CHOICES.NONE;
      } else if (someRestricted) {
        return ACCOUNT_811_RESTRICTION_CHOICES.SELECTED;
      } else {
        return ACCOUNT_811_RESTRICTION_CHOICES.ALL;
      }
    },
  },
  methods: {
    async saveAccount811Access(choice) {
      await axios.delete(
        `${APIURL}/locate_request_provider_account_restrictions/${this.selectedUser.user_id}`
      );
      if (ACCOUNT_811_RESTRICTION_CHOICES.NONE === choice) {
        await axios.post(
          `${APIURL}/locate_request_provider_account_restrictions/${this.selectedUser.user_id}`,
          {
            locate_request_provider_account_ids:
              this.locateRequestProviderAccounts.map(
                (s) => s.locate_request_provider_account_id
              ),
          }
        );
      }
      await this.onAccountsSaved();
    },
    async getLocateRequestProviderAccounts() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/locate_request_provider_accounts/all`);
      this.locateRequestProviderAccounts = results;
    },
    async getLocateRequestProviderAccountRestrictionsByUserId() {
      const {
        data: { results },
      } = await axios.get(
        `${APIURL}/locate_request_provider_account_restrictions/${this.selectedUser.user_id}`
      );
      this.locateRequestProviderAccountRestrictions = results;
    },
    async onAccountsSaved() {
      this.showAccount811AccessDialog = false;
      await this.getLocateRequestProviderAccounts();
      await this.getLocateRequestProviderAccountRestrictionsByUserId();
    },
  },
  async beforeMount() {
    await this.getLocateRequestProviderAccounts();
    await this.getLocateRequestProviderAccountRestrictionsByUserId();
  },
};
</script>

<style>
.gap {
  gap: 5px;
}
</style>
