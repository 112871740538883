var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list-item",
    { staticClass: "px-0", attrs: { id: "userAccessPermissions" } },
    [
      _c(
        "v-list-item-content",
        { staticClass: "pb-0" },
        [
          _c(
            "section",
            { staticClass: "d-flex align-center justify-space-between" },
            [
              _c("div", [_vm._v("811 Codes")]),
              _c(
                "div",
                { staticClass: "d-flex gap" },
                [
                  _c(
                    "v-chip",
                    {
                      attrs: {
                        color:
                          _vm.selected811AccountRestrictionChoice ===
                          _vm.ACCOUNT_811_RESTRICTION_CHOICES.ALL
                            ? "primary"
                            : undefined,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.saveAccount811Access(
                            _vm.ACCOUNT_811_RESTRICTION_CHOICES.ALL
                          )
                        },
                      },
                    },
                    [_vm._v(" All ")]
                  ),
                  _c(
                    "v-chip",
                    {
                      attrs: {
                        color:
                          _vm.selected811AccountRestrictionChoice ===
                          _vm.ACCOUNT_811_RESTRICTION_CHOICES.SELECTED
                            ? "primary"
                            : undefined,
                      },
                      on: {
                        click: function ($event) {
                          _vm.showAccount811AccessDialog = true
                        },
                      },
                    },
                    [_vm._v(" Selected ")]
                  ),
                  _c(
                    "v-chip",
                    {
                      attrs: {
                        color:
                          _vm.selected811AccountRestrictionChoice ===
                          _vm.ACCOUNT_811_RESTRICTION_CHOICES.NONE
                            ? "primary"
                            : undefined,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.saveAccount811Access(
                            _vm.ACCOUNT_811_RESTRICTION_CHOICES.NONE
                          )
                        },
                      },
                    },
                    [_vm._v(" None ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm.showAccount811AccessDialog
            ? _c("Account811AccessDialog", {
                attrs: {
                  showAccount811AccessDialog: _vm.showAccount811AccessDialog,
                  locateRequestProviderAccounts:
                    _vm.locateRequestProviderAccounts,
                  locateRequestProviderAccountRestrictions:
                    _vm.locateRequestProviderAccountRestrictions,
                  selectedUser: _vm.selectedUser,
                },
                on: {
                  "account-dialog-close": function ($event) {
                    _vm.showAccount811AccessDialog = false
                  },
                  "accounts-saved": _vm.onAccountsSaved,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }