var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-3" },
    [
      _c("p", { staticClass: "caption" }, [_vm._v("API URL")]),
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0 my-0", attrs: { cols: "12" } },
                    [
                      _c("validation-provider", {
                        attrs: {
                          name: "API URL",
                          rules: {
                            regex:
                              /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    "hide-details": "auto",
                                    name: "apiUrl",
                                    "background-color": "white",
                                    label: "API URL",
                                    "error-messages": errors,
                                    success: valid,
                                    hint: "Example: https://myCity.com/cityworks/Services/",
                                    "persistent-hint": "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$emit(
                                        "cityworks-user-group-info-changed",
                                        _vm.cityworksInfo
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.cityworksInfo.api_url,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.cityworksInfo,
                                        "api_url",
                                        $$v
                                      )
                                    },
                                    expression: "cityworksInfo.api_url",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }