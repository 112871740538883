var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.userGroup.access_811_tms_app
      ? _c(
          "div",
          [
            _vm._m(0),
            _c(
              "v-card",
              { attrs: { id: "userPermissionsInspect" } },
              [
                _c(
                  "v-card-text",
                  [
                    _c("p", { staticClass: "py-0 my-0" }, [
                      _vm._v("User Can:"),
                    ]),
                    _c("v-switch", {
                      staticClass: "py-0 my-1",
                      attrs: { label: "Assign Tickets", "hide-details": "" },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("input", _vm.user)
                        },
                      },
                      model: {
                        value: _vm.user.assignTickets,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "assignTickets", $$v)
                        },
                        expression: "user.assignTickets",
                      },
                    }),
                    _c("v-switch", {
                      staticClass: "py-0 my-1",
                      attrs: {
                        label: "Be Assigned Tickets",
                        "hide-details": "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("input", _vm.user)
                        },
                      },
                      model: {
                        value: _vm.user.isLocator,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "isLocator", $$v)
                        },
                        expression: "user.isLocator",
                      },
                    }),
                    _c("v-switch", {
                      staticClass: "py-0 my-1",
                      attrs: {
                        label: "View Tickets Assigned to Others",
                        "hide-details": "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("input", _vm.user)
                        },
                      },
                      model: {
                        value: _vm.user.viewTicketsAssignedToOthers,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "viewTicketsAssignedToOthers", $$v)
                        },
                        expression: "user.viewTicketsAssignedToOthers",
                      },
                    }),
                    _c("v-switch", {
                      staticClass: "py-0 my-1",
                      attrs: {
                        label: "Receive 811 Notifications",
                        "hide-details": "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("input", _vm.user)
                        },
                      },
                      model: {
                        value: _vm.user.receive811Notifications,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "receive811Notifications", $$v)
                        },
                        expression: "user.receive811Notifications",
                      },
                    }),
                    _c("v-switch", {
                      staticClass: "py-0 my-1",
                      attrs: {
                        label: "View Ticket Dashboard",
                        "hide-details": "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("input", _vm.user)
                        },
                      },
                      model: {
                        value: _vm.user.viewDashboard,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "viewDashboard", $$v)
                        },
                        expression: "user.viewDashboard",
                      },
                    }),
                    _c("validation-provider", {
                      attrs: { name: "Reports To" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-select", {
                                  staticClass: "pb-4",
                                  staticStyle: { width: "150px" },
                                  attrs: {
                                    label: "Reports To",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "reportsTo",
                                    items: _vm.userChoices,
                                    "item-text": "label",
                                    "item-value": "value",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$emit("input", _vm.user)
                                    },
                                  },
                                  model: {
                                    value: _vm.user.reportsToUserId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.user, "reportsToUserId", $$v)
                                    },
                                    expression: "user.reportsToUserId",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        382181928
                      ),
                    }),
                    _c("v-divider"),
                    _c("Account811Form", {
                      attrs: { selectedUser: _vm.selectedUser },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.userGroup.access_field_reports_app
      ? _c(
          "div",
          [
            _vm._m(1),
            _c(
              "v-card",
              { attrs: { id: "userPermissionsInspect" } },
              [
                _c(
                  "v-card-text",
                  {},
                  [
                    _c("validation-provider", {
                      attrs: { name: "User Type", rules: "required" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-select", {
                                  staticClass: "pb-4",
                                  staticStyle: { width: "150px" },
                                  attrs: {
                                    label: "User Type",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "userType",
                                    items: _vm.filteredUserTypeChoices,
                                    "item-text": "label",
                                    "item-value": "value",
                                  },
                                  on: { change: _vm.onUserTypeChange },
                                  model: {
                                    value: _vm.userTypeChoice,
                                    callback: function ($$v) {
                                      _vm.userTypeChoice = $$v
                                    },
                                    expression: "userTypeChoice",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3258729072
                      ),
                    }),
                    _c("v-divider"),
                    _vm.departments.length > 0
                      ? _c("DepartmentForm", {
                          attrs: { selectedUser: _vm.selectedUser },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      { staticClass: "d-flex justify-space-between align-center py-0 my-0" },
      [
        _c("p", { staticClass: "caption py-0 mb-2 mt-4" }, [
          _vm._v("Permissions - UtiliSync TMS"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-space-between align-center" },
      [
        _c("div", { staticClass: "caption py-0 mb-2 mt-4" }, [
          _vm._v(" Permissions - UtiliSync Inspect "),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }