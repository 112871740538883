var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list-item",
    { staticClass: "px-0", attrs: { id: "departmentPermissions" } },
    [
      _c(
        "v-list-item-content",
        { staticClass: "pb-0" },
        [
          _c(
            "section",
            { staticClass: "d-flex align-center justify-space-between" },
            [
              _c("div", [_vm._v("Departments")]),
              _c(
                "div",
                { staticClass: "d-flex gap" },
                [
                  _c(
                    "v-chip",
                    {
                      attrs: {
                        color: _vm.userHasAccessToAllDepartments
                          ? "primary"
                          : undefined,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.enableAllUserDepartmentAccess()
                        },
                      },
                    },
                    [_vm._v(" All ")]
                  ),
                  _c(
                    "v-chip",
                    {
                      attrs: {
                        color: _vm.userHasAccessToSomeDepartments
                          ? "primary"
                          : undefined,
                      },
                      on: {
                        click: function ($event) {
                          _vm.showDepartmentAccessDialog = true
                        },
                      },
                    },
                    [_vm._v(" Selected ")]
                  ),
                  _c(
                    "v-chip",
                    {
                      attrs: {
                        color: _vm.userHasAccessToNoDepartments
                          ? "primary"
                          : undefined,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.disableAllUserDepartmentAccess()
                        },
                      },
                    },
                    [_vm._v(" None ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm.showDepartmentAccessDialog
            ? _c("DepartmentAccessDialog", {
                attrs: {
                  showDepartmentAccessDialog: _vm.showDepartmentAccessDialog,
                  selectedUser: _vm.selectedUser,
                },
                on: {
                  "department-dialog-close": function ($event) {
                    _vm.showDepartmentAccessDialog = false
                  },
                  saved: _vm.onDepartmentAccessSaved,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }