var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600px", persistent: "" },
      model: {
        value: _vm.showCityworksIntegrationDialog,
        callback: function ($$v) {
          _vm.showCityworksIntegrationDialog = $$v
        },
        expression: "showCityworksIntegrationDialog",
      },
    },
    [
      _vm.auth.organization.access_cityworks_811_integration ||
      _vm.auth.organization.access_cityworks_ams_integration
        ? _c(
            "v-card",
            [
              _c("validation-observer", {
                ref: "cityworksForm",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ valid }) {
                        return [
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "elevation-0",
                              attrs: { dark: "", color: "primary" },
                            },
                            [
                              _c("v-toolbar-title", [
                                _vm._v("Cityworks Integration"),
                              ]),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", dark: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit(
                                        "cityworks-integration-dialog-close"
                                      )
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-close")])],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            {
                              staticStyle: {
                                "overflow-y": "auto",
                                height: "60vh",
                              },
                            },
                            [
                              _c("ApiUrl", {
                                attrs: {
                                  cityworksIntegrationInfo:
                                    _vm.cityworksIntegrationInfo,
                                },
                                on: {
                                  "cityworks-user-group-info-changed":
                                    function ($event) {
                                      _vm.cityworksIntegrationInfo = {
                                        ..._vm.cityworksIntegrationInfo,
                                        ...$event,
                                      }
                                    },
                                },
                              }),
                              _vm.showSave811TicketsToCityworksSection
                                ? _c("Save811TicketsToCityworks", {
                                    attrs: {
                                      cityworksIntegrationInfo:
                                        _vm.cityworksIntegrationInfo,
                                    },
                                    on: {
                                      "cityworks-user-group-info-changed":
                                        function ($event) {
                                          _vm.cityworksIntegrationInfo = {
                                            ..._vm.cityworksIntegrationInfo,
                                            ...$event,
                                          }
                                        },
                                      "cityworks-integration-settings-changed":
                                        function ($event) {
                                          _vm.cityworksIntegrationSettings = {
                                            ..._vm.cityworksIntegrationSettings,
                                            ...$event,
                                          }
                                        },
                                    },
                                  })
                                : _vm._e(),
                              _c("BadRequestErrorDialog", {
                                attrs: {
                                  showBadRequestErrorDialog: Boolean(
                                    _vm.badRequestError
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "d-flex justify-end pa-5" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { disabled: !valid, color: "primary" },
                                  on: { click: _vm.saveCityworksSettings },
                                },
                                [_vm._v(" Save ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2157713943
                ),
              }),
            ],
            1
          )
        : _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "elevation-0",
                  attrs: { dark: "", color: "primary" },
                },
                [
                  _c("v-toolbar-title", [_vm._v("Cityworks Integration")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("cityworks-integration-dialog-close")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-text", { staticClass: "py-5" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.showChat()
                      },
                    },
                  },
                  [_vm._v("Contact sales")]
                ),
                _vm._v(" to get licensed for this integration. "),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }