<template>
  <v-app>
    <v-main fluid tag="section" class="pt-0 page-background mainArea">
      <TopBar title="Organization" />

      <div class="page-background d-flex justify-center px-1">
        <v-card class="elevation-0 my-16" style="background-color: #f1f2f1">
          <v-card-text class="elevation-0 pa-0">
            <LicensingPlan />
            <Integrations :userGroup="userGroup" />
            <Departments />
            <Users :userGroup="userGroup" @user-array="users = $event" />
          </v-card-text>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "@/components/app/TopBar.vue";
import Integrations from "@/components/organization/Integrations.vue";
import Departments from "@/components/organization/Departments.vue";
import Users from "@/components/organization/Users.vue";
import LicensingPlan from "@/components/organization/LicensingPlan.vue";
import authObjectMixin from "@/mixins/authObjectMixin";
import usetifulMixin from "@/mixins/usetifulMixin";

export default {
  name: "Organization",
  components: {
    TopBar,
    Integrations,
    Departments,
    Users,
    LicensingPlan,
  },
  data() {
    return {
      auth: {},
      userGroup: {},
      users: [],
    };
  },
  mixins: [authObjectMixin, usetifulMixin],
  methods: {
    async getUserGroup() {
      this.auth = JSON.parse(localStorage.getItem("auth"));
      const { organization } = this.auth;
      this.userGroup = organization;
    },
  },
  async beforeMount() {
    if (localStorage.getItem("message-received")) {
      await this.getUserGroup();
      return;
    }
    await this.updateAuthObject();
    localStorage.setItem("message-received", true);
    await this.getUserGroup();
  },
};
</script>
