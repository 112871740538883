var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "400px", persistent: "" },
      model: {
        value: _vm.showGisConnectionDialog,
        callback: function ($$v) {
          _vm.showGisConnectionDialog = $$v
        },
        expression: "showGisConnectionDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", color: "primary" },
            },
            [
              _c("v-toolbar-title", [_vm._v("GIS Connection Settings")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("gis-connection-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "py-5" },
            [
              _c("p", { staticClass: "caption" }, [
                _vm._v("GIS Connection Information"),
              ]),
              _c(
                "v-card",
                { staticClass: "my-3" },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "validation-observer",
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "Connection Type",
                              rules: "required",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors, valid }) {
                                  return [
                                    _c("v-select", {
                                      attrs: {
                                        label: "Connection Type",
                                        "hide-details": "auto",
                                        "error-messages": errors,
                                        success: valid,
                                        color: "primary",
                                        name: "connectionType",
                                        items: _vm.CONNECTION_TYPE_CHOICES,
                                        "item-text": "label",
                                        "item-value": "value",
                                        id: "connectionTypeDropdown",
                                      },
                                      model: {
                                        value: _vm.connectionType,
                                        callback: function ($$v) {
                                          _vm.connectionType = $$v
                                        },
                                        expression: "connectionType",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.connectionType === _vm.CONNECTION_TYPES.PORTAL
                ? _c("PortalConnection", {
                    on: { "portal-updated": _vm.onPortalUpdated },
                  })
                : _vm._e(),
              _vm.connectionSuccess
                ? _c(
                    "div",
                    { staticClass: "py-3", attrs: { color: "#093637" } },
                    [
                      _vm.connectionType === _vm.CONNECTION_TYPES.PORTAL
                        ? [_vm._v(" Connected to ArcGIS Enterprise! ")]
                        : [_vm._v(" Connected to ArcGIS Online! ")],
                    ],
                    2
                  )
                : _vm.connectionFailed
                ? _c("div", { staticClass: "red--text py-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.connectionErrorMsg || "Connection Failed") +
                        " "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end px-5 py-3" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: !_vm.connectionType || _vm.connectionSuccess,
                    color: "primary",
                    id: "connectButton",
                  },
                  on: { click: _vm.connect },
                },
                [
                  _c(
                    "span",
                    {
                      style: {
                        color:
                          !_vm.connectionType || _vm.connectionSuccess
                            ? "gray"
                            : "white",
                      },
                    },
                    [_vm._v(" Connect ")]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }